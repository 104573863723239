export default {
  headers: [
    {
      id: 'main-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/zlarin/logo.png',
        url: 'https://whisselrealty.com/',
      },
      breakpoint: 1199,
      desktopMenu: {
        type: 'default',
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-zlarin-desktop-header-logo-container',
          imageClassName: 'on-zlarin-desktop-header-logo',
        },
        headerClassName: 'on-zlarin-desktop-header',
        headerSectionsClassName: 'on-zlarin-desktop-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '' || null,
        itemClassName: 'on-zlarin-desktop-item',
        menuClassName: 'on-zlarin-desktop-menu',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-zlarin-desktop-item-folder',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'buy-item',
            content: 'Buy',
            menuItems: [
              {
                id: 'buy-item-buy',
                url: 'http://search.whisselrealty.com/',
                content: 'Buy',
              },
              {
                id: 'sell-item-cash-offer',
                url: 'https://cash-offer-zlarin.netlify.app/',
                content: 'Cash Offer',
              },
            ],
          },
          {
            id: 'sell-item',
            content: 'Sell',
            menuItems: [
              {
                id: 'sell-item-home-val',
                url: 'https://whisselrealty.com/home-valuation',
                content: 'Home Valuation',
              },
              {
                id: 'sell-item-homes-for-sale',
                url: 'https://whisselrealty.com/blog',
                content: 'San Diego Homes For Sale | Whissel Realty',
              },
              {
                id: 'sell-item-why-with-us',
                url: 'https://whisselrealty.com/why-list-with-us',
                content: 'Why List With Us',
              },
              {
                id: 'buy-item-trade-in',
                url: 'https://trade-in.whisselrealty.com/',
                content: 'Trade In',
              },
            ],
          },
          {
            id: 'communities-item',
            content: 'Communities',
            menuItems: [
              {
                id: 'communities-item-all',
                url: 'https://whisselrealty.com/communities',
                content: 'All Communities',
              },
              {
                id: 'communities-item-san-diego',
                url: 'https://whisselrealty.com/communities/san-diego-real-estate',
                content: 'San Diego',
              },
              {
                id: 'communities-item-el-cajon',
                url: 'https://whisselrealty.com/communities/el-cajon-real-estate',
                content: 'El Cajon',
              },
              {
                id: 'communities-item-la-mesa',
                url: 'https://whisselrealty.com/communities/la-mesa-real-estate',
                content: 'La Mesa',
              },
              {
                id: 'communities-item-lakeside',
                url: 'https://whisselrealty.com/communities/lakeside-real-estate',
                content: 'Lakeside',
              },
              {
                id: 'communities-item-north-park',
                url: 'https://whisselrealty.com/communities/north-park-real-estate',
                content: 'North Park',
              },
              {
                id: 'communities-item-poway',
                url: 'https://whisselrealty.com/communities/poway-real-estate',
                content: 'Poway',
              },
              {
                id: 'communities-item-ramona',
                url: 'https://whisselrealty.com/communities/ramona-real-estate',
                content: 'Ramona',
              },
              {
                id: 'communities-item-ramona-ramona',
                url: 'https://whisselrealty.com/communities/ramona-ramona-real-estate',
                content: 'Ramona (Ramona)',
              },
              {
                id: 'communities-item-san-diego-estates',
                url: 'https://whisselrealty.com/communities/san-diego-country-estates-real-estate',
                content: 'San Diego Country Estates',
              },
              {
                id: 'communities-item-santee',
                url: 'https://whisselrealty.com/communities/santee-real-estate',
                content: 'Santee',
              },
              {
                id: 'communities-item-serra-mesa',
                url: 'https://whisselrealty.com/communities/serra-mesa-real-estate',
                content: 'Serra Mesa',
              },
              {
                id: 'communities-item-university-heights',
                url: 'https://whisselrealty.com/communities/university-heights-real-estate',
                content: 'University Heights',
              },
              {
                id: 'communities-item-alpine',
                url: 'https://whisselrealty.com/communities/alpine-real-estate',
                content: 'Alpine',
              },
              {
                id: 'communities-item-bankers-hill',
                url: 'https://whisselrealty.com/communities/bankers-hill-real-estate',
                content: 'Bankers Hill',
              },
              {
                id: 'communities-item-bay-park',
                url: 'https://whisselrealty.com/communities/bay-park-real-estate',
                content: 'Bay Park',
              },
              {
                id: 'communities-item-cardiff',
                url: 'https://whisselrealty.com/communities/cardiff-real-estate',
                content: 'Cardiff',
              },
              {
                id: 'communities-item-carlsbad',
                url: 'https://whisselrealty.com/communities/carlsbad-real-estate',
                content: 'Carlsbad',
              },
              {
                id: 'communities-item-carlsbad-village',
                url: 'https://whisselrealty.com/communities/carlsbad-village-real-estate',
                content: 'Carlsbad Village',
              },
              {
                id: 'communities-item-clairemont',
                url: 'https://whisselrealty.com/communities/clairemont-real-estate',
                content: 'Clairemont',
              },
              {
                id: 'communities-item-del-mar',
                url: 'https://whisselrealty.com/communities/del-mar-real-estate',
                content: 'Del Mar',
              },
              {
                id: 'communities-item-del-mar-heights',
                url: 'https://whisselrealty.com/communities/del-mar-heights-real-estate',
                content: 'Del Mar Heights',
              },
              {
                id: 'communities-item-downtown',
                url: 'https://whisselrealty.com/communities/downtown-real-estate',
                content: 'Downtown',
              },
              {
                id: 'communities-item-east-village',
                url: 'https://whisselrealty.com/communities/east-village-real-estate',
                content: 'East Village',
              },
              {
                id: 'communities-item-encanto',
                url: 'https://whisselrealty.com/communities/encanto-real-estate',
                content: 'Encanto',
              },
              {
                id: 'communities-item-encinitas',
                url: 'https://whisselrealty.com/communities/encinitas-real-estate',
                content: 'Encinitas',
              },
            ],
          },
          {
            id: 'financing-item',
            url: 'https://whisselrealty.com/financing',
            content: 'Financing',
          },
          {
            id: 'blog-item',
            url: 'https://whisselrealty.com/blog-index-1',
            content: 'Blog',
          },
          {
            id: 'about-item',
            content: 'About',
            menuItems: [
              {
                id: 'about-item-our-team',
                url: 'https://whisselrealty.com/team-1',
                content: 'Our Team',
              },
              {
                id: 'about-item-testimonials',
                url: 'https://whisselrealty.com/testimonials',
                content: 'Testimonials',
              },
              {
                id: 'about-item-join-our-team',
                url: 'https://www.thewhisselway.com/info-9655-9460',
                content: 'Join Our Team',
              },
              {
                id: 'about-item-contact-us',
                url: 'https://whisselrealty.com/new-contact-us',
                content: 'Contact Us',
              },
            ],
          },
          {
            id: 'home-item',
            url: 'https://whisselrealty.com/',
            content: 'Home',
          },
          {
            id: 'on-zlarin-header-phone-item',
            iconSet: 'fa',
            className: 'phone',
            content: '619-639-4012',
            url: 'tel:619-639-4012',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-zlarin-mobile-header-logo-container',
          imageClassName: 'on-zlarin-mobile-header-logo',
        },
        type: 'mobile-default',
        menuOpener: {
          className: 'on-test',
          content: 'Open',
        },
        headerClassName: 'on-zlarin-mobile-header',
        headerClassNameOverride: '',
        mobileMenuClassName: 'on-zlarin-mobile-menu',
        menuClassName: 'on-zlarin-mobile-folder',
        itemClassName: 'on-zlarin-mobile-item',
        menuLabelActiveClassName: 'on-zlarin-mobile-folder-active',
        menuItemClassName: 'on-zlarin-mobile-item-folder',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'buy-item',
            content: 'Buy',
            menuItems: [
              {
                id: 'buy-item-buy',
                url: 'http://search.whisselrealty.com/',
                content: 'Buy',
              },
              {
                id: 'sell-item-cash-offer',
                url: 'https://cash-offer-zlarin.netlify.app/',
                content: 'Cash Offer',
              },
            ],
          },
          {
            id: 'sell-item',
            content: 'Sell',
            menuItems: [
              {
                id: 'sell-item-home-val',
                url: 'https://whisselrealty.com/home-valuation',
                content: 'Home Valuation',
              },
              {
                id: 'sell-item-homes-for-sale',
                url: 'https://whisselrealty.com/blog',
                content: 'San Diego Homes For Sale | Whissel Realty',
              },
              {
                id: 'sell-item-why-with-us',
                url: 'https://whisselrealty.com/why-list-with-us',
                content: 'Why List With Us',
              },
              {
                id: 'buy-item-trade-in',
                url: 'https://trade-in.whisselrealty.com/',
                content: 'Trade In',
              },
            ],
          },
          {
            id: 'communities-item',
            content: 'Communities',
            menuItems: [
              {
                id: 'communities-item-all',
                url: 'https://whisselrealty.com/communities',
                content: 'All Communities',
              },
              {
                id: 'communities-item-san-diego',
                url: 'https://whisselrealty.com/communities/san-diego-real-estate',
                content: 'San Diego',
              },
              {
                id: 'communities-item-el-cajon',
                url: 'https://whisselrealty.com/communities/el-cajon-real-estate',
                content: 'El Cajon',
              },
              {
                id: 'communities-item-la-mesa',
                url: 'https://whisselrealty.com/communities/la-mesa-real-estate',
                content: 'La Mesa',
              },
              {
                id: 'communities-item-lakeside',
                url: 'https://whisselrealty.com/communities/lakeside-real-estate',
                content: 'Lakeside',
              },
              {
                id: 'communities-item-north-park',
                url: 'https://whisselrealty.com/communities/north-park-real-estate',
                content: 'North Park',
              },
              {
                id: 'communities-item-poway',
                url: 'https://whisselrealty.com/communities/poway-real-estate',
                content: 'Poway',
              },
              {
                id: 'communities-item-ramona',
                url: 'https://whisselrealty.com/communities/ramona-real-estate',
                content: 'Ramona',
              },
              {
                id: 'communities-item-ramona-ramona',
                url: 'https://whisselrealty.com/communities/ramona-ramona-real-estate',
                content: 'Ramona (Ramona)',
              },
              {
                id: 'communities-item-san-diego-estates',
                url: 'https://whisselrealty.com/communities/san-diego-country-estates-real-estate',
                content: 'San Diego Country Estates',
              },
              {
                id: 'communities-item-santee',
                url: 'https://whisselrealty.com/communities/santee-real-estate',
                content: 'Santee',
              },
              {
                id: 'communities-item-serra-mesa',
                url: 'https://whisselrealty.com/communities/serra-mesa-real-estate',
                content: 'Serra Mesa',
              },
              {
                id: 'communities-item-university-heights',
                url: 'https://whisselrealty.com/communities/university-heights-real-estate',
                content: 'University Heights',
              },
              {
                id: 'communities-item-alpine',
                url: 'https://whisselrealty.com/communities/alpine-real-estate',
                content: 'Alpine',
              },
              {
                id: 'communities-item-bankers-hill',
                url: 'https://whisselrealty.com/communities/bankers-hill-real-estate',
                content: 'Bankers Hill',
              },
              {
                id: 'communities-item-bay-park',
                url: 'https://whisselrealty.com/communities/bay-park-real-estate',
                content: 'Bay Park',
              },
              {
                id: 'communities-item-cardiff',
                url: 'https://whisselrealty.com/communities/cardiff-real-estate',
                content: 'Cardiff',
              },
              {
                id: 'communities-item-carlsbad',
                url: 'https://whisselrealty.com/communities/carlsbad-real-estate',
                content: 'Carlsbad',
              },
              {
                id: 'communities-item-carlsbad-village',
                url: 'https://whisselrealty.com/communities/carlsbad-village-real-estate',
                content: 'Carlsbad Village',
              },
              {
                id: 'communities-item-clairemont',
                url: 'https://whisselrealty.com/communities/clairemont-real-estate',
                content: 'Clairemont',
              },
              {
                id: 'communities-item-del-mar',
                url: 'https://whisselrealty.com/communities/del-mar-real-estate',
                content: 'Del Mar',
              },
              {
                id: 'communities-item-del-mar-heights',
                url: 'https://whisselrealty.com/communities/del-mar-heights-real-estate',
                content: 'Del Mar Heights',
              },
              {
                id: 'communities-item-downtown',
                url: 'https://whisselrealty.com/communities/downtown-real-estate',
                content: 'Downtown',
              },
              {
                id: 'communities-item-east-village',
                url: 'https://whisselrealty.com/communities/east-village-real-estate',
                content: 'East Village',
              },
              {
                id: 'communities-item-encanto',
                url: 'https://whisselrealty.com/communities/encanto-real-estate',
                content: 'Encanto',
              },
              {
                id: 'communities-item-encinitas',
                url: 'https://whisselrealty.com/communities/encinitas-real-estate',
                content: 'Encinitas',
              },
            ],
          },
          {
            id: 'financing-item',
            url: 'https://whisselrealty.com/financing',
            content: 'Financing',
          },
          {
            id: 'blog-item',
            url: 'https://whisselrealty.com/blog-index-1',
            content: 'Blog',
          },
          {
            id: 'about-item',
            content: 'About',
            menuItems: [
              {
                id: 'about-item-our-team',
                url: 'https://whisselrealty.com/team-1',
                content: 'Our Team',
              },
              {
                id: 'about-item-testimonials',
                url: 'https://whisselrealty.com/testimonials',
                content: 'Testimonials',
              },
              {
                id: 'about-item-join-our-team',
                url: 'https://www.thewhisselway.com/info-9655-9460',
                content: 'Join Our Team',
              },
              {
                id: 'about-item-contact-us',
                url: 'https://whisselrealty.com/new-contact-us',
                content: 'Contact Us',
              },
            ],
          },
          {
            id: 'home-item',
            url: 'https://whisselrealty.com/',
            content: 'Home',
          },
          {
            id: 'on-zlarin-header-phone-item',
            iconSet: 'fa',
            className: 'phone',
            content: '619-639-4012',
            url: 'tel:619-639-4012',
          },
        ],
      },
    },
  ],
  footers: {
    id: 'on-zlarin-footer-wrapper',
    className: 'on-zlarin-footer-wrapper',
    footer: [
      {
        id: 'on-zlarin-footer-first',
        display: true,
        className: 'on-zlarin-footer-section on-zlarin-footer-section-first',
        sectionClassName: 'on-zlarin-footer-section-container',
        columns: [
          {
            id: 'on-zlarin-footer-first-col-1',
            className: 'on-zlarin-footer-column on-zlarin-footer-column-one ',
            style: {},
            items: [
              {
                id: 'on-zlarin-footer-first-col-1-1',
                type: 'image',
                className: 'on-zlarin-footer-logo',
                alt: 'img',
                url: 'https://si-homelight.s3.amazonaws.com/sites/zlarin/logo-footer.png',
              },
              {
                id: 'on-zlarin-footer-first-col-1-2',
                type: 'heading',
                className: 'on-zlarin-footer-copyright-text',
                data: '©eXp Realty of California 2022',
              },
              {
                id: 'on-zlarin-footer-first-col-1-3',
                type: 'heading',
                className: 'on-zlarin-footer-copyright-text',
                data: 'All Rights Reserved',
              },
              {
                id: 'on-zlarin-footer-first-col-1-4',
                type: 'heading',
                className: 'on-zlarin-footer-copyright-text',
                style: {},
                data: 'eXp Realty of California - CA DRE# 01878277',
              },
              {
                id: 'zlarin-footer-second-socials',
                type: 'socialIcons',
                itemClassName: 'on-zlarin-footer-socials',
                style: {},
                items: [
                  {
                    id: 'zlarin-footer-twitter',
                    url: 'https://twitter.com/',
                    iconTypeClassName: 'fab',
                    iconClassName: 'twitter',
                  },
                  {
                    id: 'zlarin-footer-insta',
                    url: 'https://www.instagram.com/',
                    iconTypeClassName: 'fab',
                    iconClassName: 'instagram',
                  },
                  {
                    id: 'zlarin-footer-face',
                    url: 'https://www.facebook.com/',
                    iconTypeClassName: 'fab',
                    iconClassName: 'facebook-f',
                  },
                ],
              },
            ],
          },
          {
            id: 'on-zlarin-footer-second-col',
            className: 'on-zlarin-footer-column',
            items: [
              {
                id: 'on-zlarin-footer-second-col-1',
                type: 'heading',
                elementType: 'h1',
                className: 'on-zlarin-footer-heading',
                style: {},
                data: 'POPULAR LINKS',
              },
              {
                id: 'on-zlarin-footer-second-col-2',
                type: 'menu',
                className: 'on-zlarin-footer-menu',
                itemClassName: 'mb-0 on-zlarin-footer-menu-popular',
                items: [
                  {
                    id: 'on-zlarin-footer-second-col-2-1',
                    type: 'heading',
                    data: 'Meet Our Team',
                    url: 'https://whisselrealty.com/team-page',
                  },
                  {
                    id: 'on-zlarin-footer-second-col-2-2',
                    type: 'heading',
                    data: 'Join Us',
                    url: 'https://www.thewhisselway.com/info-9655-9460',
                  },
                  {
                    id: 'on-zlarin-footer-second-col-2-3',
                    type: 'heading',
                    data: 'Blog',
                    url: 'https://whisselrealty.com/blog-index-1',
                  },
                  {
                    id: 'on-zlarin-footer-second-col-2-4',
                    type: 'heading',
                    data: 'Communities',
                    url: 'https://whisselrealty.com/communities',
                  },
                  {
                    id: 'on-zlarin-footer-second-col-2-5',
                    type: 'heading',
                    data: 'Contact Us',
                    url: 'https://whisselrealty.com/new-contact-us',
                  },
                ],
              },
            ],
          },
          {
            id: 'on-zlarin-footer-',
            className: 'on-zlarin-footer-third-col',
            items: [
              {
                id: 'on-zlarin-footer-third-col-1',
                type: 'heading',
                elementType: 'h1',
                className: 'on-zlarin-footer-heading',
                style: {},
                data: 'EXPLORE',
              },
              {
                id: 'on-zlarin-footer-third-col-2',
                type: 'menu',
                className: 'on-zlarin-footer-menu',
                itemClassName: 'on-zlarin-footer-menu-popular',
                items: [
                  {
                    id: 'con-zlarin-footer-third-col-2-1',
                    type: 'heading',
                    data: 'Buy Properties',
                    url: 'https://search.whisselrealty.com/',
                  },
                  {
                    id: 'on-zlarin-footer-third-col-2-2',
                    type: 'heading',
                    data: 'Sell Properties',
                    url: 'https://whisselrealty.com/why-list-with-us',
                  },
                  {
                    id: 'on-zlarin-footer-third-col-2-3',
                    type: 'heading',
                    data: '7 Day Listing Launch',
                    url: 'http://7day.kylewhissel.com/',
                  },
                  {
                    id: 'on-zlarin-footer-third-col-2-4',
                    type: 'heading',
                    data: 'Simple Sale Program',
                    url: 'http://simplesale.kylewhissel.com/',
                  },
                  {
                    id: 'on-zlarin-footer-third-col-2-5',
                    type: 'heading',
                    data: 'Cash Close',
                    url: 'http://cashclose.kylewhissel.com/',
                  },
                  {
                    id: 'on-zlarin-footer-third-col-2-6',
                    type: 'heading',
                    data: 'Trade In Program',
                    url: 'http://tradein.kylewhissel.com/',
                  },
                  {
                    id: 'on-zlarin-footer-third-col-2-7',
                    type: 'heading',
                    data: 'Buyer VIP',
                    url: 'http://vip.kylewhissel.com/',
                  },
                ],
              },
            ],
          },
          {
            id: 'on-zlarin-footer-fourth-col',
            className: 'on-zlarin-footer-company-about',
            style: {},
            items: [
              {
                id: 'on-zlarin-footer-second-col-1',
                type: 'heading',
                elementType: 'h1',
                className: 'on-zlarin-footer-heading',
                data: 'COMPANY',
              },
              {
                id: 'on-zlarin-footer-fourth-col-1',
                type: 'menu',
                className: '',
                style: {},
                items: [
                  {
                    id: 'on-zlarin-footer-fourth-col-2',
                    data: 'EXp Realty Of California',
                    className: 'on-zlarin-footer-info-item',
                  },
                ],
              },
              {
                id: 'on-zlarin-footer-fourth-col-5',
                type: 'itemContent',
                className: 'on-zlarin-footer-contact',
                style: {},
                items: [
                  {
                    id: 'on-zlarin-footer-fourth-col-5-1',
                    content: 'Phone: ',
                  },
                  {
                    id: 'on-zlarin-footer-fourth-col-5-2',
                    content: '(619) 613-2493',
                  },
                ],
              },
              {
                id: 'on-zlarin-footer-fourth-col-6',
                type: 'itemContent',
                className: 'mb-0 on-zlarin-footer-contact',
                style: {},
                items: [
                  {
                    id: 'on-zlarin-footer-fourth-col-6-1',
                    content: 'Email: ',
                  },
                  {
                    id: 'on-zlarin-footer-fourth-col-6-2',
                    content: 'info@Whisselrealty.Com',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'on-zlarin-second-footer',
        display: true,
        className: 'on-zlarin-second-footer-section',
        sectionClassName: 'on-zlarin-second-footer-section-container',
        columns: [
          {
            id: 'zlarin-footer-disclaimer',
            className: 'align-center on-zlarin-footer-privacy-col',
            style: {},
            items: [
              {
                id: 'zlarin-footer-privacy-policy',
                type: 'heading',
                className: 'on-zlarin-footer-privacy-heading',
                data: 'PRIVACY POLICY',
                url: 'https://search.whisselrealty.com/privacy-policy',
              },
              {
                id: 'zlarin-footer-san-diego-image',
                type: 'image',
                elementType: 'img',
                className: 'zlarin-footer-san-diego-image',
                url: 'https://si-homelight.s3.amazonaws.com/sites/zlarin/logo-footer-san-diego.png',
              },
              {
                id: 'zlarin-footer-more-info',
                type: 'heading',
                className: 'on-zlarin-footer-text-1',
                elementType: 'p',
                data: 'This information is deemed reliable but not guaranteed. You should rely on this information only to decide whether or not to further investigate a particular property. BEFORE MAKING ANY OTHER DECISION, YOU SHOULD PERSONALLY INVESTIGATE THE FACTS (e.g. square footage and lot size) with the assistance of an appropriate professional. You may use this information only to identify properties you may be interested in investigating further. All uses except for personal, non-commercial use in accordance with the foregoing purpose are prohibited. Redistribution or copying of this information, any photographs or video tours is strictly prohibited. This information is derived from the Internet Data Exchange (IDX) service provided by San Diego MLS. Displayed property listings may be held by a brokerage firm other than the broker and/or agent responsible for this display. The information and any photographs and video tours and the compilation from which they are derived is protected by copyright. Compilation ©2022 San Diego MLS. Data last updated at: Fri Jul 01 2022 08:10:28 GMT+0200 (Central European Summer Time)',
              },
              {
                id: 'zlarin-footer-copyright',
                type: 'heading',
                className: 'on-zlarin-footer-text-2',
                elementType: 'p',
                data: 'Copyright © 2022 California Regional MLS. All Rights Reserved. Information herein is deemed reliable but not guaranteed and is provided exclusively for consumers personal, non-commercial use, and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Data last refreshed Wed Feb 16 2022 00:13:02 GMT+0100 (Central European Standard Time).',
              },
              {
                id: 'zlarin-footer-dmca',
                type: 'heading',
                url: 'https://search.whisselrealty.com/idx-information',
                className: 'on-zlarin-footer-dmca',
                data: 'DMCA Notice',
              },
              {
                id: 'zlarin-footer-privacy-policy-two',
                type: 'heading',
                url: 'https://search.whisselrealty.com/privacy-policy',
                className: 'on-zlarin-footer-privacy-heading',
                style: {},
                data: 'Privacy Policy',
              },
            ],
          },
        ],
      },
    ],
  },
  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
  },
  siteData: {
    agency: 'Whissel Realty',
    agentDescription: 'Top agents in San Diego County',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/zlarin/agent.png',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/zlarin/hero-bg.jpg',
    heroClassName: 'on-zlarin-hero',
  },
};
